.main-holder {
  display: grid;
  height: 120vh;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 0.2fr 1.5fr 1.2fr 0.8fr;
  grid-template-areas:
    "nav nav nav"
    "main main main"
    "content1 content2 content3"
    "footer footer footer";
  grid-gap: 0.4rem;
}

#nav {
  background-color: aquamarine;
  grid-area: nav;
}

#main {
  background-color: white;
  grid-area: main;
}

#content1 {
  background-color: white;
  border: 1px solid rgba(216, 211, 211, 0.733);
  border-radius: 10px;
  box-shadow: 10px 10px 8px #888888;
  max-width: 100%;
  grid-area: content1;
  margin: 30px;
}

#content2 {
  background-color: white;
  border: 1px solid rgba(216, 211, 211, 0.733);
  border-radius: 10px;
  box-shadow: 10px 10px 8px #888888;
  max-width: 100%;
  grid-area: content2;
  margin: 30px;
}

#content3 {
  background-color: white;
  border: 1px solid rgba(216, 211, 211, 0.733);
  border-radius: 10px;
  box-shadow: 10px 10px 8px #888888;
  max-width: 100%;
  grid-area: content3;
  margin: 30px;
}

#footer {
  background-color: darkblue;
  grid-area: footer;
  background-color: rgba(241, 93, 48, 0.918)
}

@media only screen and (max-width: 550px) {
  .main-holder {
    grid-template-columns: 1fr;
    grid-template-rows: 0.4fr 2.2fr 1.2fr 1.2fr 1.2fr 1fr;
    grid-template-areas:
      "nav"
      "main"
      "content1"
      "content2"
      "content3"
      "footer";
  }
}

#nav {
  background-color: white;
  /* background-image: linear-gradient(to right, rgb(9, 153, 158),rgb(82, 241, 218)) */
}

.welcome {
  display: flex;
  align-content: center;
}

#profile-pic {
  max-height: 50vh;
}

.title {
  display: flex;
  align-content: center;
  flex-direction: column;
  font-family: "Noto Sans JP", sans-serif;
  width: 65vw;
  margin-top: 5%;
  margin-bottom: 5%;
}

#welcome-message {
  font-size: 60px;
  color: rgb(71, 71, 71);
  letter-spacing: 2px;
  margin-left: 5vw;
}

#info { 
    margin-top: 5px;
    margin-left: 5vw;
    font-size: 17px;
}

.socials-holder {
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  margin-left: 5vw;
}

#github,
#linkedin,
#twitter {
  font-size: 35px;
  color: rgba(241, 93, 48, 0.918);
  padding: 10px;
}
.content-class {
  font-family: "Noto Sans JP", sans-serif;
  display: flex;
  justify-content: center;
}
.content-holder {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.project-photos {
  display: flex;
  align-content: center;
  justify-content: center;
  width: 70%;
  margin-left: 15%;
  margin-right: 15%;
  margin-top: 5%;
  margin-bottom: 10px;
}

.more-buttons {
  font-family: "Noto Sans JP", sans-serif;
  color: white;
  font-size: 16px;
  display: flex;
  align-content: center;
  justify-content: center;
  margin-bottom: 15px;
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;
  min-height: 25px;
  padding: 0;
  border: none;
  border-radius: 10px;
  background-color: rgba(241, 93, 48, 0.918);
  text-decoration: none;
}

/* Footer Section */ 


#logos { 
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: white;
  font-size: 30px;
}

.logo { 
  margin: 20px;
}